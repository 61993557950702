* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Lato;
}

:root {
    --light-purple-op: #D5DBFF66;
    --light-purple: #D5DBFF;
    /* --purple-op: #3127820D; */
    --purple-op: #3127821A;
    --purple: #312782;
    --dark-purple: #1F285B;
    --milky: #EFB994;
    
    --custom-light-gray: #919191;
    --custom-error: #E57373;
    --dark-orange: #E07D31;
    --silver: #F8F8F8;
    --light-skyblue: #264E701A;
    --dark-skyblue: #264E70;
    --dark-blue: #18334A;
    --dark-night: #191345;
    --custom-brown: #674E3D;
    --olive: #679186;
    --rose-gold: #F9B4AB;
    --border-light-gray: #E3E3E3;
    --border-purple: #2E2B7D;
    --border-orange: #D38143;

    /* New Color */
    --custom-tomato: #BB342F;
    --beige: #E7D7C1;
    --light-orange: #DDB988;
    --light-pink: #BB342F1A;
    --transparent-white: #E5E5E526;
    --liver: #4E1513;
    
    --coral : #FF8233;
    --mas-blue: #2E5077;
    --mas-blue-light: #2E507733;
    --mas-blue-transparent:#2E50770D;
    --dark-chocolate: #2B1508;
    --custom-gray: #707070;
    --dark-brown: #1F0807;
    --transparent-red: #BB342F4D;
    --coral-transparent: #FF82331A;
    --dark-sienna : #311C0F;
    --black-op5 : #0000005C;
}

/* Change Bootstrap */
/* .page-item.active .page-link{
    background-color: var(--dark-skyblue);
    border-color: var(--dark-skyblue);
} 

.page-link { color: var(--dark-blue); }

*/

.page-item.active .page-link{
    background-color: var(--coral);
    border-color: var(--coral);
}

.page-link { color: var(--dark-purple); }

/* Dimensions */
/* Width */
.max-container { max-width: 1600px; }
.vw-100 { width: 100vw; }
.w-90 { width:90%; }
.w-40 { width:40%; }
.mw-500 { max-width: 500px; }
.w-max-350 { max-width: 350px; }
.w-max-600 { max-width: 600px; }
.w-logo-max { max-width:170px; align-self:center;}
.w-min-500 {min-width: 500px;}

/* Height */
.vh-100 { height: 100vh; }
.h-auto { height:200px; }
.h-0 { height: 0; }
.mh-0 { max-height:0; overflow:hidden; }
.mh-500 { max-height:500px; overflow-y: auto; }
.h-max-500 { max-height:500px; overflow-y: auto; }
.h-500 { height:500px; }
.h-nav-min { min-height:90px; }

/* QUBE */
.qube-qr { width: 170px; height: 170px; }

/* Positions */
.top-0 { top:0; }
.bottom-0 { bottom:0; }
.right-0 { right:0; }
.left-0 { left:0; }

/* Z-Index */
.zIndex-1 { z-index: 1; }
.zIndex-max { z-index: 999; }

/* Transition */
.transition { transition: 0.3s ease-out; }
.translateX-100 { transform: translateX(100%); } 

/* Cursor */
.pointer { cursor: pointer !important; }

/* Padding */
.p-container { padding: 30px 0px !important; }
.py-110 { padding-top: 110px !important; padding-bottom: 110px !important; }
.pt-110 { padding-top: 110px !important; }
.pb-110 { padding-bottom: 110px !important; }

/* Text */
.capitalize-first { text-transform: lowercase; }
.capitalize-first::first-letter { text-transform: uppercase; }
.word-break { word-break: break-all !important; }
.justify {
    text-align: justify;
    text-justify: inter-character;
}

/* Background colors */
.bg-white { background-color: #FFFFFF !important; }
.bg-white-op { background-color: #FFFFFF33 !important; }
.bg-black { background-color: #000000; }
.bg-dark { background-color: #333333; }
.bg-purple { background-color: var(--purple); }
.bg-light-purple { background-color: var(--light-purple); }
.bg-purple-op { background-color: var(--purple-op); }
.bg-light-purple-op { background-color: var(--light-purple-op); }
.bg-dark-purple { background-color: var(--dark-purple); }
.bg-dark-night { background-color: var(--dark-night); }
.bg-milky { background-color: var(--milky); }
.bg-silver { background-color: var(--silver); }
.bg-light-skyblue { background-color: var(--light-skyblue); }
.bg-rose-gold { background-color: var(--rose-gold); }

.bg-tomato { background-color: var(--custom-tomato); }
.bg-beige { background-color: var(--beige); }
.bg-light-orange { background-color: var(--light-orange); }
.bg-transparent-red { background-color: var(--transparent-red); }
.bg-liver { background-color: var(--liver); }

.bg-coral { background-color: var(--coral); }
.bg-mas-blue { background-color: var(--mas-blue); }
.bg-mas-blue-light { background-color: var(--mas-blue-light); }
.bg-mas-blue-transparent { background-color: var(--mas-blue-transparent); }
.bg-dark-sienna { background-color: var(--dark-sienna); }




/* Borders */
.border { border: 1px solid red !important; }
.border-light-gray { border: 1px solid var(--border-light-gray); }
.border-gray { border: 1px solid var(--custom-gray); }
.border-top-light-gray { border-top: 1px solid var(--border-light-gray); }
.border-bottom-light-gray { border-bottom: 1px solid var(--border-light-gray); }
.border-green { border: 1px solid rgb(23, 211, 23); }
.border-olive { border: 1px solid var(--olive); }
.border-purple { border: 1px solid var(--border-purple); }

.border-tomato { border: 1px solid var(--custom-tomato); }
.border-coral { border: 1px solid var(--coral); }
.border-mas-blue { border: 1px solid var(--mas-blue); }


/* Border Radius */
.circle { border-radius: 100% !important; }
.round-10 { border-radius: 10px; }
.round-20 { border-radius: 20px; }
.round-50 { border-radius: 50px; }
.rounded { border-radius: 5px !important; }

/* Colors */
.required-color { color: #c40e0e !important; }
.white { color: #FFFFFF !important; }
.black { color: #000000; }
.dark { color: #333333; }
.purple { color: var(--purple) !important; }
.dark-purple { color: var(--dark-purple); }
.milky { color: var(--milky) !important; }
.silver { color: var(--silver) }
.brown { color: var(--custom-brown)}
.dark-orange { color: var(--dark-orange)}
.light-skyblue { color: var(--light-skyblue) }
.dark-blue { color: var(--dark-blue) }
.olive { color: var(--olive) }
.rose-gold { color: var(--rose-gold) }

.tomato { color: var(--custom-tomato) !important }
.liver { color: var(--liver) !important }
.dark-brown { color: var(--dark-brown) !important }
.light-orange { color: var(--light-orange) !important }


.coral { color: var(--coral) !important }
.mas-blue { color: var(--mas-blue) !important }
.dark-chocolate { color: var(--dark-chocolate) !important }





/* Box-shadow */
.b-shadow-light-dark { box-shadow:0 0 6px #00000029; }
.b-shadow-light-dark-chocolate { box-shadow:0 0 6px #0000005C; }

.b-shadow-mid-dark { box-shadow:0 0 6px #00000077 inset; }
.b-shadow-nav { box-shadow:0 7px 7px #00000044; }

/* fonts */
@font-face { font-family: Lato; src: url(../fonts/Lato.ttf); }
.font-lato { font-family: Lato, sans-serif; }
.bold { font-weight:bold; }
.italic { font-style: italic; }
.fs-8 { font-size: 0.5rem; }
.fs-10 { font-size: 0.625rem; }
.fs-12 { font-size: 0.75rem; }
.fs-14 { font-size: 0.875rem; }
.fs-16 { font-size: 1rem; }
.fs-18 { font-size: 1.125rem; }
.fs-20 { font-size: 1.25rem; }
.fs-22 { font-size: 1.375rem; }
.fs-24 { font-size: 1.5rem; }
.fs-26 { font-size: 1.625rem; }
.fs-28 { font-size: 1.75rem; }
.fs-30 { font-size: 1.875rem; }
.fs-32 { font-size: 2rem; }
.fs-40 { font-size: 2.5rem; }

/* FontAwesome */
.fa-times { transition: 0.3s; }
.fa-times:hover { color: #c40e0e; }

/* Image */
.cover { object-fit: cover; }

/* Flex and Grid */
.d-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-1 {
    flex: 1;
}
.grid-center {
    display: grid;
    place-items: center;
}

.grid-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.grid-item {
    overflow: hidden;
    background-color: var(--border-light-gray);
    height: 220px;
  }

/* Layout */
.main-layout {
    display:flex; 
    flex-direction:column; 
    min-height: 100vh;
}
.mainLayout-main { flex:1 1 0 !important; }

/* FORMS */
/* Form Layout */
.side-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--purple);
    margin-bottom: 30px;
}
/***********************************************/
.side-subtitle {
    font-size: 1.75rem;
    font-weight: bold;
    color: var(--coral);
    margin-bottom: 15px;
}

.side-paragraph {
    font-size: 1.125rem;
    color: var(--dark-chocolate);
    margin-bottom: 15px;
}
/***********************************************/


.aside-formLayout{
    position: relative;
    overflow-x: hidden;
}
.poligono {
    width:500px;
    height: 500px;
    position: absolute;
    border-radius: 100%;
    background-color: #ffffff11;
}
.pol-1 {
    top: -200px;
    right:-200px;
}
.pol-2 {
    bottom: -200px;
    left:-200px;
}
.form { font-size:1.125rem; }
.form-header {
    font-size: 2rem;
    font-weight: bold;
}
.form-label {
    font-size: 1.25rem;
    margin:10px 0;
}
/* .text-decoration-none {
    text-decoration: none !important;
} */
.form-link {
    /* text-decoration: none; */
    /* color: var(--milky); */
}
.form-link:hover {
    text-decoration:underline;
    /* color: var(--milky); */
}
.show-password {
    display: flex;
    align-items: center;
    position: absolute;
    top:0;
    right:20px;
    height: 100%;
}
.show-password > * { cursor: pointer; }
.forget-text { margin-top: 15px; }
.form-footer { font-size: 1.125rem; }
.login-side-wrapper { padding:3% 17% }

/* Input */
.custom-input {
    outline: none;
    border: 1px solid var(--custom-light-gray);
    border-radius: 5px;
    padding: 0 10px;
    height:39px;
    width: 100%;
}
.custom-input:focus {
    box-shadow: 0 0 5px 1px var(--purple);
    border: none;
}
.form-input {
    outline: none;
    overflow: hidden;
    display: block;
    border: 1px solid var(--custom-gray);
    border-radius: 5px;
    width: 100%;
    height:56px;
    padding:17px 20px;
}
.form-input::placeholder { color: var(--custom-light-gray); }
.input-error-message { min-height:25px; }
input[type="file"], input[type="image"] { display: none; }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
input[type=number] { -moz-appearance: textfield; }
::placeholder { color:  var(--custom-light-gray) !important; opacity: 1; }
:-ms-input-placeholder { color:  var(--custom-light-gray) !important; }
::-ms-input-placeholder { color:  var(--custom-light-gray) !important; }
.custom-file-upload {
    padding: 0px 12px;
    border: 1px solid var(--custom-gray);
    border-radius: 5px;
    width: 100%;
    height:56px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    color: var(--custom-light-gray);
}

/* Button */
.sel-btn {
    height: 55px;
    /* border-radius: 50px; */
    background-color: #fff;
    margin: 50px 0;
}
.form-btn {
    transition: 0.3s;
    /* background-color: var(--milky); */
    outline: none;
    /* border: 1px solid var(--milky); */
    border-radius: 28px;
    width: 100%;
    height: 55px;
    margin: 50px 0;
    /* padding: 0 17px; */
    font-weight: bold;
    color: var(--purple);
}
.custom-btn {
    transition: 0.3s;
    overflow: hidden;
    outline: none;
    border-radius: 50px;
    min-width: 140px;
    min-height: 40px;
    padding: 0 15px;
    font-weight: bold;
}
.custom-btn2 {
    transition: 0.3s;
    overflow: hidden;
    outline: none;
    border-radius: 50px;
    min-width: 140px;
    min-height: 41px;
    padding: 12px 21px;
    font-weight: bold;
}
.btn-midnight-outline { 
    transition: 0.3s;
    border: 1px solid var(--border-purple);
    background-color: #FFFFFF;
    color: var(--purple);
}
.btn-midnight-outline:hover {
    background-color: var(--purple);
    color:#FFFFFF;
    font-weight: normal;
}
.btn-midnight-fill { 
    border: 1px solid var(--border-purple);
    background-color: var(--purple);
    color: #FFFFFF;
    font-weight: normal;
}
.btn-midnight-fill:hover {
    background-color: #FFFFFF;
    color:var(--purple);
    font-weight: bold;
}

/***************************************************************/

.btn-mas-blue,
.btn-mas-blue-fill,
.btn-mas-blue-nb,
.btn-mas-blue-outline:hover {
    background-color: var(--mas-blue);
    border: 1px solid var(--mas-blue);
    color: #FFFFFF;
}

.btn-mas-blue:hover {
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
}

.btn-mas-blue-outline,
.btn-mas-blue-fill:hover {
    background-color: #FFFFFF;
    border: 1px solid var(--mas-blue);
    color: var(--mas-blue);
}

.btn-mas-blue-nb:hover,
.btn-white {
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    color: var(--mas-blue);
}

.btn-white:hover {
    border: 1px solid var(--mas-blue);
}

.btn-coral {
    background-color: var(--coral);
    border: 1px solid var(--coral);
    color: #FFFFFF !important;
}
.btn-coral:hover {
    /* border: 1px solid var(--mas-blue); */
    color: var(--mas-blue) !important;
}

.btn-tran-coral {
    background-color: var(--coral-transparent);
    border: 1px solid var(--coral-transparent);
    color: var(--coral);
}

.btn-tran-coral:hover {
    background-color: var(--coral-transparent);
    border: 1px solid var(--coral);
    color: var(--coral);
}


.btn-beige,
.btn-beige-fill,
.btn-beige-outline:hover {
    background-color: var(--beige);
    border: 1px solid var(--beige);
    color: var(--dark-brown);
}

.btn-beige:hover {
    border: 1px solid var(--dark-brown);
}

.btn-beige-outline,
.btn-beige-fill:hover {
    background-color: transparent;
    border: 1px solid var(--beige);
    color: var(--beige);
}

.btn-light-pink {
    border: 1px solid var(--light-pink);
    background-color: var(--light-pink);
    color: var(--custom-tomato);
}

.btn-light-pink:hover {
    border: 1px solid var(--custom-tomato);
}

.btn-tomato {
    background-color: var(--custom-tomato);
    border: 1px solid var(--custom-tomato);
    color: #FFFFFF;
}

.btn-tomato-fill,
.btn-tomato-outline:hover {
    background-color: var(--custom-tomato);
    border: 1px solid var(--custom-tomato);
    color: #FFFFFF;
}

.btn-tomato-outline,
.btn-tomato-fill:hover {
    background-color: transparent;
    border: 1px solid var(--custom-tomato);
    color: var(--custom-tomato);
}

.btn-light-orange {
    background-color: var(--light-orange);
    border: 1px solid var(--light-orange);
    color: var(--dark-brown);
}

.btn-light-orange:hover {
    border: 1px solid var(--dark-brown);
}

/***************************************************************/



.btn-milky,
.btn-milky-fill,
.btn-milky-outline:hover {
    border: 1px solid var(--milky);
    background-color: var(--milky);
    color: var(--custom-brown);
}
.btn-milky-outline,
.btn-milky:hover {
    border: 2px solid var(--milky);
    background-color: transparent;
    color: var(--milky);
}
.btn-milky-fill:hover,
.btn-rose-gold-fill:hover { 
    border: 1px solid var(--border-purple); 
}

.btn-rose-gold-fill {
    border: 1px solid var(--rose-gold);
    background-color: var(--rose-gold);
    color: var(--custom-brown);
}
.btn-orange {
    border: 1px solid var(--border-orange);
    background-color: var(--dark-orange);
    color: #FFF;
}
.btn-orange:hover,
.btn-orange-outline {
    border: 1px solid var(--border-orange);
    background-color: #fff;
    color: var(--dark-orange);
}
.btn-orange-outline:hover {
    border: 1px solid var(--milky);
    background-color: var(--milky);
    color: var(--custom-brown);
}
.btn-light-purple {
    border: 1px solid var(--light-purple-op);
    background-color: var(--light-purple-op);
    color: var(--purple);
}
.btn-light-purple:hover,
.btn-light-purple:focus { border: 1px solid var(--purple); }

/* Checkbox */
.accent-milky { accent-color: var(--milky); }
.form-checkbox {
    outline: none;
    vertical-align: middle; 
    width: 24px;
    height: 24px;
    margin-right: 10px;
    accent-color: white;
    cursor: pointer;
}
.checkbox {
    outline: none;
    vertical-align: middle; 
    width: 18px;
    height: 18px;
    border-radius: 2px;
    cursor: pointer;
    border-color: var(--border-light-gray) !important;
}
.checkbox-milky {
    accent-color: var(--milky);
}
/***********************************************************************/

.checkbox-tomato {
    accent-color: var(--custom-tomato);
}

.checkbox-coral {
    accent-color: var(--coral);
}

/***********************************************************************/

.checkbox-label {
    transition: 0.3s;
    cursor: pointer;
}

/* Date Picker */
.react-datepicker-wrapper { width:100%; }

/* React-Card_Flip */
.react-card-flip { height:100%; }

/* Swiper */
.swiper:hover .swiper-button-prev,
.swiper:hover .swiper-button-next { opacity: 1 !important; }
.swiper-button-prev,
.swiper-button-next {
    transition: 0.3s;
    background-color: #FFFFFFaa;
    opacity: 0 !important;
    top: 0% !important;
    height: 100% !important;
    width: 50px !important;
    color: #264E70 !important;
    margin: 0 !important;
}
.swiper-button-prev { left: 0 !important; }
.swiper-button-next { right: 0 !important; }
.swiper-pagination-bullet-active { background-color: #264E70 !important;
}
.swiper-pagination-fraction {
    position: absolute !important;
    right:15px !important;
    bottom: 15px !important;
    background-color: #FFF;
    border-radius: 5px;
    width:83px !important;
    height:30px !important;
    font-size:14px;
    margin-left: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-pagination-fraction::after{
    content:' Foto';
    margin-left: 5px;
}
.swiper-container .swiper-zoom-container>img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
}

/* Table */
.t-head { overflow-x: auto; }
.house-header {
    display: inline-flex;
    justify-content: space-between;
    list-style-type: none;
    background-color: var(--light-skyblue);
    border-radius:5px;
    min-width:100%;
    height:44px;
    padding: 0;
}
.house-header-content {
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: var(--dark-brown); */
    font-weight: bold;
}
.house-list-wrapper {
    overflow-y:auto;
    display:inline-block;
    min-width: 100%; 
}
.house-list-item {
    transition: 0.3s;
    /* overflow:hidden; */
    overflow-x: hidden;
    list-style-type: none;
    display: inline-flex;
    justify-content: space-between;
    border:1px solid #E3E3E3; 
    border-radius:5px; 
    min-width:100%; 
    height:67px; 
    margin:10px 0;
    padding:0;
}
.house-list-item:hover { box-shadow:0 0 10px 1px #00000029; }
.house-content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dark-purple);
    /* font-weight: bold; */
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
}
.acc-item-header,
.acc-sub-item  {
    transition: 0.3s;
    border: 1px solid var(--border-light-gray);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.acc-item-header:hover,
.acc-sub-item:hover {
    box-shadow:0 0 5px 1px #00000029;
    font-weight: bold;
}
.acc-item-content { overflow: hidden; }

/* NAVS */
.nav-item {
    transition: 0.3s;
    /* cursor:pointer; */
    font-weight: 500;
    white-space: nowrap;
}
.nav-item:hover {
    font-weight: bold;
    color: var(--milky);
}

/* Modal  */
.customModal {
    position: fixed;
    /* width: 100vw; */
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top:0;
    left:0;
    background-color: #2B2B2B80;
    z-index: 999;
}
.modal-box {
    max-width: 800px;
    width:'70%';
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    background-color: #FFFFFF;
    margin: 100px auto;
    padding:20px;
}
.modal-close-btn {
    outline: none;
    border-radius: 100%;
    padding: 5px 10px;
    border: 1px solid     
}

/* Common */
.header {
    overflow: hidden;
    min-height:90px;
    /* height:90px; */
    margin:auto !important;
    padding:20px 40px;
    display: flex;
    align-items: center;
}
.footer {
    min-height:110px;
    /* height:110px; */
    margin:auto !important;
    padding:20px 40px;
    display: flex;
    align-items: center;
}
.footer-link {
    color: #FFF;
    text-decoration: none;
}
.footer-link:hover { color: var(--light-orange) !important; }

/* 404 */
.notfound-404-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.notfound-404 { text-align: center; }
.notfound-404 h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 230px;
    margin: 0 0 15px;
    font-weight: 900;
    background: url(../images/404.png) no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: cover;
    background-position: center;
}
.notfound-404 h2 {
    font-family: 'Montserrat', sans-serif;
    color: #000;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
}
.notfound-404 > .link {
    transition: 0.3s;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background: var(--dark-blue);
    display: inline-block;
    padding: 15px 30px;
    border-radius: 40px;
    color: #fff;
    font-weight: 700;
    -webkit-box-shadow: 0px 4px 15px -5px var(--dark-blue);
    box-shadow: 0px 4px 15px -5px var(--dark-blue);
}
.notfound-404 > .link:hover { background: var(--dark-skyblue); }

/* Policy */
.policy-item,
.terms-item {
    transition: 0.3s;
    cursor:pointer;
    margin-bottom: 10px;
    padding:0 30px;
    text-indent: -25px;
}
.active {
    font-weight: bold;
    /* color: var(--dark-brown) !important; */
    color: var(--dark-purple) !important;
    /* font-size: 1.125rem; */
}
.policy-item:hover,
.terms-item:hover { color: var(--dark-blue); }

/* Other */
.avatar-wrapper {
    overflow:hidden;
    border-radius:100%; 
    width:50px; 
    height:50px; 
}
.scroll-up {
    position: fixed;
    bottom:30px;
    right:30px;
    border-radius: 50%;
    width:50px;
    height: 50px;
}
/********************************************************************************************/
.piani > li:before {
    content: "\2022";
    color: var(--coral);
    font-weight: bold;
    margin-right:10px;
}
/********************************************************************************************/

.piani-popular > li:before {
    content: "\2022";
    color: white;
    font-weight: bold;
    margin-right:10px;
}
.select-perPage {
    position: absolute;
    top:0;
    left:0.5rem;
}
.image-counter {
    position: absolute;
    right:30px;
    bottom: 30px;
    background-color: #FFF;
    border-radius: 5px;
    width:83px;
    height:30px;
    font-size:12px;
}
.location,
.download-item {
    transition: 0.3s;
    border: 1px solid var(--border-light-gray);
    height:50px;
}
.location:hover,
.download-item:hover { box-shadow: 0 0 6px #00000029; }
.download-btn {
    transition: 0.3s;
    width:30px;
    height: 30px;
}
.download-btn:hover { background-color: var(--olive); }
.download-btn:hover > .fa { color: #FFF !important; }

.filter-doc {
    cursor:pointer;
    transition: 0.3s;
    height: 70px;
}
.filter-doc:hover { background-color: var(--light-purple-op);}

/* Media query */
@media screen and (max-width: 575px) {
    .select-perPage {
        position: relative;
        top:0;
        left:0;
    }
    .notfound-404 h1 { font-size: 70px; }
}
@media screen and (min-width: 576px) and (max-width: 991px) {
    .notfound-404 h1 { font-size: 150px; }
}
@media screen and (max-width: 991px) {
    html { font-size:14px; }
}
@media screen and (min-width: 992px) {
    .vh-lg-100 { height:100vh; }
    .h-lg-100 { height:100% }
}
/* @media screen and (min-width: 576px) {
   .qr-card-shadow { box-shadow: 0 3px 10px #3127824A; } 
} */
/* .qr-card-shadow { box-shadow: 0 3px 10px #3127824A; }  */
.qr-card-shadow { box-shadow: 0 3px 10px var(--light-pink); } 


table {
    border: 1px solid #CCC;
    border-collapse: collapse;
    /* background-color: var(--light-skyblue); */
}
th, td {
    border:1px solid #CCC;
    padding: 15px;
}

th {
    background-color: var(--light-skyblue);
    font-size: 1.25rem;
    font-weight: bold;
    /* color: var(--dark-brown); */
}
tbody > tr:nth-child(even) {
    background-color: #f1f1f1;
}

.popular {
    position: relative;
    box-shadow: 0 4px 20px var(--mas-blue-transparent);
    overflow: hidden;
}
.popular::before {
    content:'POPULAR';
    position: absolute;
    top: 30px;
    right:-40px;
    background-color: white;
    color: var(--mas-blue);
    font-size: 0.875rem;
    font-weight: bold;
    /* width: 100px; */
    padding: 5px 50px;
    text-align: center;
    transform: rotate(45deg);
}

.video-tooltip {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;

    font-size: 0.625rem;
    background-color: var(--light-purple-op);
    position:absolute;
    top:-27px;
    right:0;
    padding: 3px 5px;
    min-width: 100px;
    text-align: center;
    border-radius: 3px;
}

.video-tooltip::after {
    content: '';
    border-top: 5px solid var(--light-purple-op);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    /* height:5px;
    width: 11px; */
    position: absolute;
    bottom:-5px;
    right:15px;
}

.video-icon:hover .video-tooltip {
    visibility: visible;
    opacity: 1;
}

.enterprise {
    transition: 0.3s;
    /* border: 1px solid transparent; */
    border: 1px solid var(--coral);
    /* box-shadow: 0px 120px 15px -90px #eaeaea; */
}